exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-exhibitions-tsx": () => import("./../../../src/pages/exhibitions.tsx" /* webpackChunkName: "component---src-pages-exhibitions-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-start-tsx": () => import("./../../../src/pages/start.tsx" /* webpackChunkName: "component---src-pages-start-tsx" */),
  "component---src-templates-artwork-artwork-detail-page-js": () => import("./../../../src/templates/artwork/artworkDetailPage.js" /* webpackChunkName: "component---src-templates-artwork-artwork-detail-page-js" */),
  "component---src-templates-artwork-collection-js": () => import("./../../../src/templates/artwork/collection.js" /* webpackChunkName: "component---src-templates-artwork-collection-js" */)
}

